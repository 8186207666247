import React, { useState, useRef, useEffect } from "react";
import { Col, message, Tabs, Steps, Form, Button, Input, Spin, Row, Alert, Modal, Radio, Space, Table, Pagination, Tag, Empty } from "antd";

import { BaseApi } from "../../../config/api/BaseApi";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import Web3 from "web3";

declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };
    

const ClaimHis = (prop:any) => {

    

    const { configApy } = prop;
    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState()
    const [historys, setHistorys]: any = useState([]);

    let web3: any

    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }
    const [param, setParam]: any = useState({
        page: 1,
        size: 100
    });

    const getHistories = async () => {
       
        await BaseApi.getHistories(param, address).then((res)=>{
           
            if(res.status == 200 && res.data && res.data.data){

                let datas:any = res.data.data;
                setHistorys(datas);
            }else{
                setHistorys([])
            }
        }).catch();
    }

    useEffect(()=>{
        if(address){
            getHistories();
        }
    }, [address, param]);

    function getFormattedDate(date:any) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let hours = date.getHours().toString().padStart(2, '0');;
        let mins = date.getMinutes().toString().padStart(2, '0');;
    
        return  year + '-' + month + '-' + day + ' ' + hours + ':'+mins;
    }

    const columns: any = [
        {
            title: <><span className="text-gray-light">TYPE</span></>,
            dataIndex: '',
            render: (text:any, record:any) => {
                return (
                    <div className="text-white">
                        Yield Earned
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">STATUS</span></>,
            dataIndex: 'status',
            render: (text:any, record:any) => {
                return (
                    <div className="text-info">
                        {!record.claimed && (
                            <div className="flex-left">
                                <div className="dot danger"></div>
                                <span>Unfinished</span>
                            </div>
                        )}
                        {record.claimed && (
                            <div className="flex-left">
                                <div className="dot success"></div>
                                <span>Completed</span>
                            </div>
                        )}
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">AMOUNT</span></>,
            dataIndex: 'amount',
            render: (text:any, record:any) => {
                return (
                    <div className="text-green">
                        {new Intl.NumberFormat("ja-JP").format(record?.amount)} HLUSD
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">HASH</span></>,
            dataIndex: 'hash',
            render: (text:any, record:any) => {
                let expoler:any = 'https://helascan.io/tx/';
               
                return (
                    <div className="text-info">
                        {record?.hash ? 
                            <a href={expoler+record?.hash} target="_blank">{`${record?.hash.substring(0, 4)}...${record?.hash.substring(record?.hash.length - 4)}`}</a>
                        : 
                        "---"}
                    
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">DATE</span></>,
            dataIndex: 'created_at',
            render: (text:any, record:any) => {

                const createdAt = new Date(record?.created_at);
        
                let datTime = createdAt.getTime();
                let newDateTime = datTime - (8*60*60);
                let newDate = new Date(newDateTime); 
                return (
                    <div className="text-gray-light">
                        { getFormattedDate(newDate) }
                    </div>
                    )
                }
        }
    ];
  
    return (
        <>
            <div className="powered mt-15" style={{marginTop: '0px !important'}}>
                <div className="powered-title">Claim History</div>
                <div className="powered-list">
                    <div className="table-responsive">
                        {historys.length > 0 ? (
                            <>
                                <Table className="table-cus table-leaderboard" columns={columns} dataSource={historys}/>
                            </>
                        ):(
                            <>
                                <Empty description={<span className="text-white">No history</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </>
                        )}
                        
                    </div>
                </div>
            </div>
           
        </>
    );
};
export default ClaimHis;
