import React, { useState, useRef, useEffect } from "react";
import { Col, message, Tabs, Steps, Form, Button, Input, Spin, Row, Alert, Modal, Radio, Space, Table, Pagination, Tag, Empty } from "antd";

import { AbiItem } from "web3-utils";
import { notiError, notiSuccess } from '../../../utils/notication';
import { RightOutlined } from "@ant-design/icons";

import { CHAINNAMEBYID } from "../../../constants";
import { BaseApi } from "../../../config/api/BaseApi";
import UserInfo from "./userInfo";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import Web3 from "web3";
import ClaimYieldAbi from '../../../config/abi/ClaimYieldAbi.json'
import WagmiConnect from "../../../component/ConnectWallet/wagmi-connect";

declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };
    

const CollectYield = (prop:any) => {

    

    const { configApy } = prop;
    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState()
    const chainId: any = selectedNetworkId;
    const [totalRow, setTotalRow] = useState(0);
    const [historys, setHistorys]: any = useState([]);
    let keyTab = localStorage.getItem('keyTab');

    let web3: any

    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");
    let signatureMeta:any = localStorage.getItem('signature');

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }


    // let nowDate = new Date(Date.now());
    // let startTime = new Date(campaign?.start_time);
    // let endTime = new Date(campaign?.end_time);

    const [loadingClaim, setLoadingClaim]: any = useState(false);
    const [isConfirmUnStake, setIsConfirmUnStake]: any = useState(false);
    const [loadingRetryClaim, setLoadingRetryClaim]: any = useState(false);
    const [loadingKey, setLoadingKey]: any = useState(0);

    const [claimInfo, setClaimInfo]: any = useState(null);

    const [param, setParam]: any = useState({
        page: 1,
        size: 100
    });

    

    const getUserClaimInfo = async (address:any) => {
        await BaseApi.getUserClaimInfo(address).then((res)=>{
            if(res.status == 200 && res.data && res.data.data){
                // if(res.data.data.claim_chain_id == chainId){
                    setClaimInfo(res.data.data);
                // }
                
            }else{
                setClaimInfo(null)
            }
        }).catch((err)=>{
            console.log('ClaimInfo', err);
        });
    }

    const getHistories = async () => {
       
        await BaseApi.getHistories(param, address).then((res)=>{
           
            if(res.status == 200 && res.data && res.data.data){

                let datas:any = res.data.data;
                setTotalRow(res.data.total);
                setHistorys(datas);
            }else{
                setHistorys([])
            }
        }).catch();
    }

    

    // useEffect(() => {
    //     if (keyTab == 'collect-yield' && address && chainId != 8668) {
    //         networkChange(8668);
    //     }
    // }, [chainId, address, keyTab]);

    useEffect(()=>{
        if(address){
            getHistories();
        }
    }, [address, param]);

    useEffect(()=>{
        if(address){
            getUserClaimInfo(address);
        }
    }, [address]);


    let rewardAmount:any = claimInfo && claimInfo?.reward ? claimInfo?.reward : 0;

    const handleClaimReward = async () => {
        try {
            setLoadingClaim(true);

            // if (chainId != 8668) {
            //     notiError('Wrong network.Please select HeLa Mainnet', 4)
            //     setLoadingClaim(false)
            //     return
            // }
            if (!signatureMeta) {
                notiError('Wrong signature', 4)
                setLoadingClaim(false)
                return
            }
            

            // call gen sign
            let data = {
                "wallet": address,
                "message": "Welcome to StableHodl!",
                "signature": signatureMeta
            }

            let rsClaim:any = await BaseApi.requestClaimYield(data);
            if(rsClaim.status && rsClaim.data.data){
                let dataClaim = rsClaim.data.data;
                let amountClaim:any = dataClaim?.claim_amount;

                if (amountClaim <= 0) {
                    notiError('Amount claim invalid', 4)
                    setLoadingClaim(false)
                    return
                }
                if (!dataClaim?.claim_sign) {
                    notiError('Claim sign invalid', 4)
                    setLoadingClaim(false)
                    return
                }
                if (!dataClaim?.claim_pool) {
                    notiError('Claim address invalid', 4)
                    setLoadingClaim(false)
                    return
                }

                let claimContract:any;
                if(dataClaim && dataClaim?.claim_pool){
                    claimContract = new web3.eth.Contract(
                        ClaimYieldAbi as unknown as AbiItem,
                        dataClaim?.claim_pool
                    );
                }
                
                
                const gasPrice = await web3.eth.getGasPrice();

                const gasEstimate = await claimContract.methods
                    .claim(
                        dataClaim?.id,
                        amountClaim,
                        dataClaim?.claim_sign
                    )
                    .estimateGas({
                        from: address,
                        // value: valueStakeConverted,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    });

                await claimContract.methods.claim(dataClaim?.id, amountClaim, dataClaim?.claim_sign)
                .send({
                    from: address,
                    gas: gasEstimate,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                })
                .then(async (resClaim: any) => {
                    
                    const interval = setInterval(function () {
                        web3.eth.getTransactionReceipt(
                            resClaim?.transactionHash,
                            async function (err: any, rec: any) {
                                if (rec) {
                                    
                                    clearInterval(interval);
                                    setLoadingClaim(false);

                                    // update hash after success

                                    let data = {
                                        "wallet": address,
                                        "message": "Welcome to StableHodl!",
                                        "signature": signatureMeta,
                                        "id": dataClaim?.id,
                                        "hash": resClaim?.transactionHash
                                    }
                                    await BaseApi.updateClaimYieldHash(data)
                                    .then((res)=>{
                                        if(res.status === 200){
                                            notiSuccess('Claim Success.', 5);
                                            setIsConfirmUnStake(false);
                                            getHistories();
                                            if(address){
                                                getUserClaimInfo(address);
                                            }
                                        }else{
                                            notiError(res?.data?.message, 5);
                                        }
                                    }).catch(()=>{
                                        notiError('Claim Failed.', 5);
                                    });
                                    
                                    // setTimeout(() => {
                                    //     setLoadingClaim(false);

                                    //     window.location.reload();
                                    // }, 3500);
                                }
                                if (err) {
                                    console.log("err", err);
                                    setLoadingClaim(false)
                                    setIsConfirmUnStake(false);
                                    notiError('Error Staking', 3)
                                }
                            }
                        )
                    }, 1000);
                })
                .catch((errStake: any) => {
                    console.log('errStake', errStake)
                    notiError(errStake?.message || errStake, 3)
                    setLoadingClaim(false)
                    setIsConfirmUnStake(false);
                })

            }else{
                notiError('Claim sign not found.', 3)
                setLoadingClaim(false);
                setIsConfirmUnStake(false);
            }

        } catch (error: any) {
            console.log('error', error?.message)
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoadingClaim(false)
            setIsConfirmUnStake(false);
        }
    };
    

    const handleRetryClaimReward = async (item:any) => {
        try {
            setLoadingRetryClaim(true);
            setLoadingKey(item?.id);

            // if (chainId != 8668) {
            //     notiError('Wrong network.Please select HeLa Mainnet', 4)
            //     setLoadingClaim(false)
            //     return
            // }
        
            let dataClaim = item;
            let amountClaim:any = dataClaim?.claim_amount;

            if (amountClaim <= 0) {
                notiError('Amount claim invalid', 4)
                setLoadingRetryClaim(false)
                return
            }
            if (!dataClaim?.claim_sign) {
                notiError('Claim sign invalid', 4)
                setLoadingRetryClaim(false)
                return
            }
            if (!dataClaim?.claim_pool) {
                notiError('Claim address invalid', 4)
                setLoadingRetryClaim(false)
                return
            }

            let claimContract:any;
            if(dataClaim && dataClaim?.claim_pool){
                claimContract = new web3.eth.Contract(
                    ClaimYieldAbi as unknown as AbiItem,
                    dataClaim?.claim_pool
                );
            }
            
            
            const gasPrice = await web3.eth.getGasPrice();

            const gasEstimate = await claimContract.methods
                .claim(
                    dataClaim?.id,
                    amountClaim,
                    dataClaim?.claim_sign
                )
                .estimateGas({
                    from: address,
                    // value: valueStakeConverted,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                });

            await claimContract.methods.claim(dataClaim?.id, amountClaim, dataClaim?.claim_sign)
            .send({
                from: address,
                gas: gasEstimate,
                gasPrice: web3.utils.toHex(String(gasPrice)),
            })
            .then(async (resClaim: any) => {
                
                const interval = setInterval(function () {
                    web3.eth.getTransactionReceipt(
                        resClaim?.transactionHash,
                        async function (err: any, rec: any) {
                            if (rec) {
                                
                                clearInterval(interval);
                                setLoadingRetryClaim(false);
                                let data = {
                                    "wallet": address,
                                    "message": "Welcome to StableHodl!",
                                    "signature": signatureMeta,
                                    "id": dataClaim?.id,
                                    "hash": resClaim?.transactionHash
                                }
                                await BaseApi.updateClaimYieldHash(data)
                                .then((res)=>{
                                    if(res.status === 200){
                                        notiSuccess('Claim Succeeded', 5);
                                        getHistories();
                                        if(address){
                                            getUserClaimInfo(address);
                                        }
                                    }else{
                                        notiError(res?.data?.message, 5);
                                    }
                                }).catch(()=>{
                                    notiError('Claim Failed.', 5);
                                });
                            }
                            if (err) {
                                console.log("err", err);
                                setLoadingRetryClaim(false)
                                notiError('Error Claim', 3)
                            }
                        }
                    )
                }, 1000);
            })
            .catch((errStake: any) => {
                console.log('errStake', errStake)
                notiError(errStake?.message || errStake, 3)
                setLoadingRetryClaim(false)
            })

        } catch (error: any) {
            console.log('error', error?.message)
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoadingRetryClaim(false)
        }
    };

    let chainName = configApy ? CHAINNAMEBYID[configApy?.claim_yield_chain] : '';

    

    



    function getFormattedDate(date:any) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let hours = date.getHours().toString().padStart(2, '0');;
        let mins = date.getMinutes().toString().padStart(2, '0');;
    
        return  year + '-' + month + '-' + day + ' ' + hours + ':'+mins;
    }

    const columns: any = [
        {
            title: <><span className="text-gray-light">TYPE</span></>,
            dataIndex: '',
            render: (text:any, record:any) => {
                return (
                    <div className="text-white">
                        Yield Earned
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">STATUS</span></>,
            dataIndex: 'status',
            render: (text:any, record:any) => {
                return (
                    <div className="text-info">
                        {!record.claimed && (
                            <div className="flex-left">
                                <div className="dot danger"></div>
                                <span>Unfinished</span>
                            </div>
                        )}
                        {record.claimed && (
                            <div className="flex-left">
                                <div className="dot success"></div>
                                <span>Completed</span>
                            </div>
                        )}
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">AMOUNT</span></>,
            dataIndex: 'amount',
            render: (text:any, record:any) => {
                return (
                    <div className="text-green">
                        {new Intl.NumberFormat("ja-JP").format(record?.amount)} HLUSD
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">HASH</span></>,
            dataIndex: 'hash',
            render: (text:any, record:any) => {
                let expoler:any = 'https://helascan.io/tx/';
                return (
                    <div className="text-info">
                        {record?.hash ? 
                            <a href={expoler+record?.hash} target="_blank">{`${record?.hash.substring(0, 4)}...${record?.hash.substring(record?.hash.length - 4)}`}</a>
                        : 
                        "---"}
                    
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">DATE</span></>,
            dataIndex: 'created_at',
            render: (text:any, record:any) => {

                const createdAt = new Date(record?.created_at);
        
                let datTime = createdAt.getTime();
                let newDateTime = datTime - (8*60*60);
                let newDate = new Date(newDateTime); 
                return (
                    <div className="text-gray-light">
                        { getFormattedDate(newDate) }
                    </div>
                    )
                }
        },
        {
            title: <><span className="text-gray-light">ACTION</span></>,
            dataIndex: 'action',
            render: (text:any, record:any) => {
                return (
                    <div className="text-info">
                        {!record.claimed && (
                            <Button className="btn-danger" type="primary" disabled={!address || loadingRetryClaim || chainId != record?.claim_chain_id} danger onClick={()=>handleRetryClaimReward(record)}><span className="">Retry {loadingRetryClaim && loadingKey == record?.id && (<Spin />)}</span></Button>
                        )}
                    </div>
                )
            }
        },
    ];

    const confirmUnStake = ()=>{
        setIsConfirmUnStake(true);
    }
  
    return (
        <>
            <div style={{ marginTop: 12 }} className="stake-block">
                <Row gutter={30}>
                    
                    <Col xs={24} sm={24}>
                        <div className="">
                            <UserInfo claimInfo={claimInfo}/>
                        </div>
                    </Col>
                    <Col xs={24} sm={24}>
                        
                        
                        <div className="text-center mt-20">
                            {configApy && address && configApy?.claim_yield_chain != chainId && (
                                <Alert className="mt-15 mb-15" type="error" message={<>Wrong network, please switch to {chainName} </>}></Alert>
                            )}
                            {address ? (
                                <>
                                    
                                    <Button size="large" className="btn-green" disabled={!address || rewardAmount <= 0.0001 || loadingClaim || configApy?.claim_yield_chain != chainId} onClick={confirmUnStake}>
                                        Claim Yield
                                    </Button>
                                    
                                </>
                            ):(
                                <>
                                    <WagmiConnect />
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="powered" style={{marginTop: '50px !important'}}>
                <div className="powered-title">Claim History</div>
                <div className="powered-list">
                    <div className="table-responsive">
                        {historys.length > 0 ? (
                            <>
                                <Table className="table-cus" columns={columns} dataSource={historys}/>
                            </>
                        ):(
                            <>
                                <Empty description={<span className="text-white">No history</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </>
                        )}
                        
                    </div>
                </div>
            </div>
            <Modal footer={false} className="modal-cus" onCancel={()=> setIsConfirmUnStake(false)} onOk={()=> setIsConfirmUnStake(false)} title={<></>} open={isConfirmUnStake}>
                <div className="text-center text-white font-24 mt-0 border-bottom-10 mb-15">Claim Yield!</div>
                <p className="text-center text-green font-18">Approve to claim assets</p>
                <div className="text-center mb-15">
                    <div className="">
                        <Button size="large" className="btn-green" disabled={!address || rewardAmount <= 0 || loadingClaim || configApy?.claim_yield_chain != chainId} onClick={handleClaimReward}>
                            {loadingClaim ? 'Approving' : 'Approve'}  {loadingClaim ? <Spin className="spin-white ml-5"/> : <RightOutlined />}
                        </Button>
                    </div>
                    
                </div>
            </Modal>
        </>
    );
};
export default CollectYield;
