
import { Col, message, Tabs, Layout, Row, Select, Empty, Tooltip, Table, Pagination, Spin, Space, Input, Button, Alert } from "antd";
import { Content, Header } from "antd/es/layout/layout";

import React, { useState, useRef, useEffect } from "react";

import { TeamOutlined, CopyOutlined } from "@ant-design/icons";
import HeaderApp from "../header-app";
import { BaseApi } from "../../config/api/BaseApi";
import type { TabsProps } from 'antd';
import Footer from "../../component/common/Footer";
import { useAccount } from "wagmi";
import Web3 from "web3";
import CopyToClipboard from "react-copy-to-clipboard";
import { notiError, notiSuccess } from "../../utils/notication";
import ClaimHis from "./tabs/claim";
import WithdrawHis from "./tabs/withdraw";
import DepositHis from "./tabs/deposit";

const { Option } = Select;
declare const window: Window &
  typeof globalThis & {
    trustwallet: any;
    ethereum: any;
    bitkeep: any;
    okexchain: any;
    safepalProvider: any;
};

const Profile = () => {

    const { address } = useAccount();
    const [refCode, setRefCode]: any = useState(null);
    const [refFriends, setRefFriends]: any = useState([]);
    const [statistics, setStatistics]: any = useState(null);
    const [code, setCode]: any = useState('');
    const [configApy, setConfigApy]: any = useState(null);
    const [claimInfo, setClaimInfo]: any = useState(null);
    let hisTab = localStorage.getItem('keyHisTab');
    const [activeTab, setActiveTab] = useState<any>(hisTab ? hisTab : 'deposit');

    let web3:any;
    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");
    let signatureMeta:any = localStorage.getItem('signature') ? localStorage.getItem('signature') : null;
    const message = "Welcome to StableHodl!";

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    const getRefCode = async (address:any) => {
        await BaseApi.getRefCode(address).then((res)=>{
            
            if(res.status == 200 && res.data && res.data.data){
                setRefCode(res.data.data);
            }else{
                setRefCode(null)
            }
        }).catch((err)=>{
            console.log('ClaimInfo', err);
        });
    }

    const getFriends = async (address:any) => {
        await BaseApi.getRefFriends(address).then((res)=>{
            
            if(res.status == 200 && res.data && res.data.data){
                setRefFriends(res.data.data);
            }else{
                setRefFriends([])
            }
        }).catch((err)=>{
            console.log('ClaimInfo', err);
        });
    }

    const getStatistics = async () => {
      await BaseApi.getStatistics().then((res)=>{
        if(res.status == 200 && res.data.data){
          setStatistics(res.data.data);
        }
      }).catch((error)=>{
        console.log(error)
      });
    }
    const getConfigApy = async () => {
        await BaseApi.getConfigApy().then((res)=>{
            if(res.status == 200 && res.data.data){
            setConfigApy(res.data.data);
            }
        }).catch((error)=>{
            console.log(error)
        });
    }

    const getUserClaimInfo = async (address:any) => {
        await BaseApi.getUserClaimInfo(address).then((res)=>{
            if(res.status == 200 && res.data && res.data.data){
                // if(res.data.data.claim_chain_id == chainId){
                    setClaimInfo(res.data.data);
                // }
                
            }else{
                setClaimInfo(null)
            }
        }).catch((err)=>{
            console.log('ClaimInfo', err);
        });
    }

    useEffect(()=>{
        if(address){
            getFriends(address);
            getUserClaimInfo(address);
        }
        
    }, [address]);
  
    useEffect(() => {
        getConfigApy();
        getStatistics();

    }, []);

    useEffect(() => {
        
        if (address) {
            getRefCode(address);
        }
    }, [address]);

    const itemsTabs: TabsProps['items'] = [
        {
            key: 'deposit',
            label: <div className="flex-left"><img src="../images/deposit.svg"></img> <span>Deposit</span></div>,
            children: <><DepositHis /></>,
        },
        {
            key: 'withdraw',
            label: <div className="flex-left"><img src="../images/withdraw.svg"></img> <span>Withdrawal</span></div>,
            children: <><WithdrawHis /></>,
        },
        {
            key: 'claim',
            label: <div className="flex-left"><img src="../images/claim.svg"></img> <span>Claim</span></div>,
            children: <><ClaimHis /></>,
        },
    ];

    

    let addressStr:any = address;
    let baseUrl:any = process.env.REACT_APP_BASE_URL;
    let refStr = `${baseUrl}?pts=${refCode?.code}`;

    const onCopy = ()=>{
        notiSuccess('Copied', 3)
    }

    const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        setCode(inputValue);
    };

    const handleSetCode = async() => {
        if(!code){
            notiError('Connect your wallet to activate Invitation Code.', 3);
            return;
        }
        if(!signatureMeta){
            notiError('Wrong signature.', 3);
            return;
        }
    
        let payload:any = {
            "wallet": address,
            "message": message,
            "signature": signatureMeta,
            "code": code.toUpperCase()
        }
        await BaseApi.setRefCode(payload).then((res)=>{
            console.log('res', res);
            if(res.status == 201 && res.data && res.data.data){
                notiSuccess('Invitation Code is valid!', 3);
                setCode('');
            }else{
                // setRefCode(null)
                notiSuccess('Referral code existed!', 3);
                setCode('');
            }
        }).catch((err)=>{
            notiError('Referral code not found!', 3);
            console.log('set ref error', err);
            setCode('');
        });
    };

    const onChangeTabs = (key: string) => {
        localStorage.setItem('keyHisTab', key);
        setActiveTab(key);
    };

    return (
        <>
            <div className="app-page bg-main">
                
                <HeaderApp />
                <div className="container">
                    <div className="profile-page">
                        <Row>
                            <Col xs={24} sm={12}>
                                <div className="profile-info">
                                    
                                    <Row gutter={30}>
                                        <Col xs={24} sm={7}>
                                            <div className="profile-img">
                                                <TeamOutlined size={50} className="icon"/>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={17}>
                                            <div className="profile-name d-flex-left gap-15">
                                                <div className="name">Member</div>
                                                <div className="role">Role: ___</div>
                                            </div>

                                            <div className="profile-ref-info mt-15">
                                                <Row gutter={30}>
                                                    <Col xs={12} sm={12}>
                                                        <div className="text-gray-light font-16">Wallet's Address:</div>
                                                        <div className="text-white weight-600 d-flex-left mt-10">
                                                            <span className="mr-5 font-16">{addressStr ? `${addressStr.substring(0, 4)}...${addressStr.substring(addressStr.length - 4)}` : "---"}</span>

                                                            <CopyToClipboard onCopy={onCopy} text={addressStr}>
                                                                <img className="w-15" src="../images/copy-ref.svg" />
                                                            </CopyToClipboard>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} sm={12}>
                                                        <div className="text-gray-light font-16">Invited Friends:</div>
                                                        <div className="text-white weight-600 mt-10 font-16">
                                                            {refCode?.total_users ? refCode?.total_users : 0}
                                                        </div>
                                                        
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={30} className="mt-15">
                                        <Col xs={24} sm={8}>
                                            <div className="profile-stake-info">
                                                <div className="text-white weight-600 font-16">Total Staked:</div>
                                                <div className="text-green weight-700 font-20 mt-15">{ claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.hlusd_amount) : 0}</div>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={8}>
                                            <div className="profile-stake-info">
                                                <div className="text-white weight-600 font-16">Total Yield Earn:</div>
                                                <div className="text-green weight-700 font-20 mt-15">{ claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.reward+claimInfo?.lock_reward) : 0}</div>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={8}>
                                            <div className="profile-stake-info">
                                                <div className="text-white weight-600 font-16">Your Points:</div>
                                                <div className="text-green weight-700 font-20 mt-15">{claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.points) : 0}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                </div>
                            </Col>
                            <Col xs={24} sm={12}>
                                <div className="profile-ref">
                                    <div className="text-white weight-600 font-18">Invitation Code:</div>
                                    <div className="ref-input d-flex-center-mobile gap-10"  style={{marginTop: '7px'}}>
                                        <div className="ref-code text-center font-16">
                                            {refStr ? `${refStr.substring(0, 18)}...${refStr.substring(refStr.length - 8)}` : "---"}
                                        </div>
                                        <div className="d-flex-mid gap-10 mt-10-mobile">
                                            <CopyToClipboard onCopy={onCopy} text={refStr}>
                                                <button className="btn-border-green d-flex-mid"><img className="w-20" src="../images/copy-ref.svg" /></button>
                                            </CopyToClipboard>
                                            <a className="btn-border-green d-flex-mid gap-10 text-white" target="_blank"
                                                href={`https://x.com/intent/post?text=I've earned stable coin and points everyday on +%40StableHodl, powered by @HeLa_Network.%0A%0AStake for high yields and bonuses with my invite code: ${refCode?.code}%0A%0A&hashtags=HeLaLabs,HeLaEcosystem,StableHodl,StakingRewards&url=${refStr}`}
                                                data-size="small">
                                                <img className="w-20" src="../images/x.svg" /> Tweet
                                               
                                            </a>
                                        </div>
                                    </div>

                                    <div className="text-white weight-600 mt-30 font-18">Input Invitation Code (Optional):</div>
                                    <div className="ref-code-input d-flex-center gap-10" style={{marginTop: '7px'}}>
                                        <Input type="text" className="add-ref" value={code} size="large" onChange={handleChangeCode} placeholder="Ex: ABCDEFGH" />
                                        <button className="btn-border-green d-flex-mid" onClick={handleSetCode} disabled={!address || code == '' || code.length != 8}>Send</button>
                                    </div>
                                    {code.length > 0 && code.length != 8 && (
                                        <>
                                            <p className="text-danger mt-10">Referral code must be 8 character</p>
                                        </>
                                    )}
                                    <div className="text-white weight-600 font-18 mt-15">Friends:</div>

                                    {refFriends.length > 0 && refFriends.map((item:any, key:any) => {

                                    return(
                                        <p className="text-white mt-10">{item?.address}</p>
                                    )
                                    })}

                                    
                                </div>
                            </Col>

                            <Col xs={24} sm={24}>
                                <div className="mt-15">
                                    <Tabs className="tabs-his mt-20" style={{ marginTop: 30 }} centered activeKey={activeTab} items={itemsTabs} onChange={onChangeTabs} />
                                </div>
                            </Col>

                            
                        </Row>

                        

                        {/* <div className="table-leader">
                            {loading ? (
                                <>
                                    <div className="text-center"><Spin size="large"/></div>
                                </>
                            ):(
                                <>
                                <Table scroll={{y: 500 }} className="table-leaderboard" pagination={false} columns={columns} dataSource={leaderboards}/>
                                </>
                            )}
                            
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default Profile;
